import React from "react"
import { useIntl, Link } from "gatsby-plugin-intl"

//Components
import Layout from "../components/layout"
import SEO from "../components/seo"

//Styles
import errorStyles from "./404.module.scss"

export default () => {
  const intl = useIntl()
  return (
    <Layout>
      <div className={errorStyles.wrapper}>
        <SEO title={intl.formatMessage({ id: "404__title" })} />
        <h1>{intl.formatMessage({ id: "404__title" })}</h1>
        <Link to="/">{intl.formatMessage({ id: "404__home_link" })}</Link>
      </div>
    </Layout>
  )
}
